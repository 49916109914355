#spinner-404 {
  border: 3px solid transparent;
  border-bottom-color: rgb(243, 234, 234);
  border-right-color: rgb(243, 234, 234);
  width: 310px;
  height: 310px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 40%;
  animation: load 2s linear infinite;

}
#spinner {
  border: 3px solid transparent;
  border-bottom-color: rgb(243, 234, 234);
  border-right-color: rgb(243, 234, 234);
  width: 110px;
  height: 110px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  animation: load 2s linear infinite;

}

@keyframes load {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

#edge {
  background-color: #fff;
  box-shadow: inset 0px 0px 20px #adabab;
  width: 100px;
  height: 100px;
  position: absolute;
  border-radius: 50%;
  overflow: hidden;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#edge-404 {
  background-color: #fff;
  box-shadow: inset 0px 0px 20px #adabab;
  width: 300px;
  height: 300px;
  position: absolute;
  border-radius: 50%;
  overflow: hidden;
  top: 50%;
  left: 40%;
  transform: translate(-50%, -50%);
}

.map-svg {
  position: absolute;
  fill-opacity: 0;
  stroke: #c5bebe;
  stroke-opacity: 1;
  stroke-width: 2;
  height: 400px;
  top: 10%;
  stroke-dasharray: 670px;
  animation: draw 5s infinite ease-in-out alternate,
    rotate 5s -2.5s infinite ease-in-out reverse;

}

.map-svg-404 {
  position: absolute;
  fill-opacity: 0;
  stroke: red;
  stroke-opacity: 1;
  stroke-width: 2;
  height: 400px;
  top: 10%;
  stroke-dasharray: 670px;
  animation: draw 5s infinite ease-in-out alternate,
    rotate 5s -2.5s infinite ease-in-out reverse;

}

/* .map-svg.error{
  stroke: #c71515;
} */

@keyframes draw {
  0% {
    stroke-dashoffset: 670px;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes rotate {
  from {
    left: -265px;
  }

  to {
    left: 0px;
  }
}

@media(max-width:480px) {

  #spinner,
  #edge {
    left: 46%
  }
}