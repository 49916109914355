.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background */
  display: none;
  /* Hidden by default */
  justify-content: center;
  align-items: center;
}

/* Modal content */
.modal-content {
  background-color: white;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

}

/* Open state for modal */
.modal.open {
  display: flex;
  z-index: 30;
}


.success-message {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300px;
  transform: translate(-50%, -50%);
  text-align: center;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

/* Flip Animation */
@keyframes flip {
  0% {
    transform: perspective(400px) rotateY(0);
  }

  100% {
    transform: perspective(400px) rotateY(180deg);
  }
}

.flip {
  animation: flip 1s ease-out;
}

@keyframes blink {

  0%,
  100% {
    @apply bg-main
  }

  50% {
    background-color: #55127c;
  }
}

.blinking-button {

  color: white;
  animation: blink 2s linear infinite;
  @apply bg-main
}

@keyframes scale-animation {

  0%,
  100% {
    transform: scale(100%);
  } 

  40% {
    transform: scale(110%);
  }

 
}

.logo-contest {
  transition: all 1s ease-in-out;
  animation-name: scale-animation;
  animation-iteration-count: 4;
  animation-duration: 2s;
}