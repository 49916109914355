.form-element-container {
    @apply grid md:grid-cols-8 gap-4
}

.form-section-title {
    @apply font-semibold font-poppins text-center text-3xl py-4
}

.form-btn {
    @apply bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600
}
.form-btn-container{
    @apply flex w-full md:w-1/2 justify-between items-center mx-auto
}