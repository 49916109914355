@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

.cool-hover {

    color: #000;
    text-decoration: none;
}

.cool-hover::after {
    content: '';
    display: block;
    width: 0;
    height: 1px;
    background: #000;
    transition: width .3s;

}

.cool-hover:hover::after {
    width: 100%;

}

textarea {
    display: hidden !important;
}

body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
}

body::-webkit-scrollbar {
    width: 6px;
    background-color: #F5F5F5;
}

body::-webkit-scrollbar-thumb {

    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #555;
    @apply rounded-b-[10px]
}

.radial-bg {
    opacity: 1;
    background-color: rgb(239, 241, 247);
    background-image: radial-gradient(rgb(41, 80, 219) 1px, rgb(53 104 255 / 2%) 1px);
    background-size: 22px 22px;
    right: 0;
    width: 22%;
    height: 60%;
    bottom: 20px;
}

.custom-scroll::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
}

.custom-scroll::-webkit-scrollbar {
    width: 0px;
    background-color: #F5F5F5;
}

.custom-scroll::-webkit-scrollbar-thumb {

    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #555;
    @apply rounded-b-[10px]
}

.slide {
    height: 32rem !important;
}

.glider-container {
    @apply !flex !gap-2 !items-center
}

.fp-watermark{
    @apply !hidden
}

.submenulist ul li a {
    position: relative;
}

@keyframes zoom-in-zoom-out {
    0% {
        transform: scale(1, 1);
    }

    50% {
        transform: scale(1.2, 1.2);
    }

    100% {
        transform: scale(1, 1);
    }
}

.selectedSector {
    @apply lg:z-30 lg:absolute lg:h-72
}

.slide-in {
    animation: slideIn 15s ease-in-out infinite;
}

@keyframes slideIn {
    0% {
        transform: translateX(0);
    }

    50% {
        transform: translateX(-200%);
    }

    100% {
        transform: translateX(0);
    }
}

.service-list img {
    @apply w-full object-cover h-44 px-[10%]
}

.service-list ul {
    @apply pt-10
}

.service-list li {
    @apply text-center pt-0
}

@font-face {
    font-family: "Bahnschrift";
    src: url("https://db.onlinewebfonts.com/t/0a6ee448d1bd65c56f6cf256a7c6f20a.eot");
    src: url("https://db.onlinewebfonts.com/t/0a6ee448d1bd65c56f6cf256a7c6f20a.eot?#iefix")format("embedded-opentype"),
        url("https://db.onlinewebfonts.com/t/0a6ee448d1bd65c56f6cf256a7c6f20a.woff2")format("woff2"),
        url("https://db.onlinewebfonts.com/t/0a6ee448d1bd65c56f6cf256a7c6f20a.woff")format("woff"),
        url("https://db.onlinewebfonts.com/t/0a6ee448d1bd65c56f6cf256a7c6f20a.ttf")format("truetype"),
        url("https://db.onlinewebfonts.com/t/0a6ee448d1bd65c56f6cf256a7c6f20a.svg#Bahnschrift")format("svg");

    font-family: "Nova Flat Book";
    src: url("https://db.onlinewebfonts.com/t/d154c43b1388b246c9ebdf27f498e064.eot");
    src: url("https://db.onlinewebfonts.com/t/d154c43b1388b246c9ebdf27f498e064.eot?#iefix")format("embedded-opentype"),
        url("https://db.onlinewebfonts.com/t/d154c43b1388b246c9ebdf27f498e064.woff2")format("woff2"),
        url("https://db.onlinewebfonts.com/t/d154c43b1388b246c9ebdf27f498e064.woff")format("woff"),
        url("https://db.onlinewebfonts.com/t/d154c43b1388b246c9ebdf27f498e064.ttf")format("truetype"),
        url("https://db.onlinewebfonts.com/t/d154c43b1388b246c9ebdf27f498e064.svg#Nova Flat Book")format("svg");
}