label {
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  color: #525d6f;
  opacity: .4;
  transition: opacity .4s ease-in-out;
  display: block;
  width: calc(100% - 48px);
  text-align: right;
  z-index: 100;
  user-select: none;
}

input[type="radio"] {
  display: none;
  width: 0;
}

label:hover,
input[type="radio"]:checked+label {
  opacity: 1;

}
input[type="radio"]:checked+label{
  font-size: 16px
}
;

.popup {
  border-radius: 12px;
  box-sizing: border-box;
  border: 16px solid #ebf0f4;
  overflow: hidden !important;
  box-shadow: 16px 16px 48px #2e364330;
  @apply bg-[#dfe6ed] 
}

.tabs {
  width: 100%;
  max-width: 320px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  position: relative;
  z-index: 0;
}

.marker {
  position: absolute;
  width: 100%;
  height: 200%;
  display: flex;
  flex-direction: column;
  top: calc(-100%);
  left: 0;
  transition: transform .3s ease-in-out;
  
}

.marker #bottom,
.marker #top {
  background-color: #ebf0f4;

}

.marker #top {
  height: calc(50%);
  margin-bottom: auto;
  border-radius: 0 0 32px 0;
}

.marker #bottom {
  height: calc(50% - 70px);
  border-radius: 0 32px 0 0;
}

