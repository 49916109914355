.grid-container-3 {
   @apply grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10
}

.grid-container-8 {
   @apply grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2 sm:gap-4 md:gap-5 lg:gap-8 py-4 sm:place-items-start
}
.gap-container {
   @apply px-4 sm:px-[10%]  xl:px-[8%] py-10 w-full 
}

.gap-container-1 {
   @apply px-8 md:px-[13%] xl:px-[23%] py-20 w-full
}

.flex-container {}

.nav-container {

}
.footer-grid-container-12{
   @apply grid grid-cols-12  gap-2 
}
.footer-nav-social-container {
   @apply flex leading-3 items-center  lg:grid grid-cols-12 lg:float-right 
}
.footer-nav-social-item{
   @apply border-[1px] w-fit p-1  scale-50
}

@keyframes slideIn {
   0% {
     transform: translateY(100%);
   }
   100% {
     transform: translateY(0);
   }
 }
 
 .animate-slideIn {
   animation: slideIn 1s ease;
 }