.carousel-container {
  position: relative;
  height: 400px;
  width: 100%;
  overflow: hidden;
}

.carousel-inner {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.carousel-item {
  float: left;
  display: none;
  transition: display 0.5s ease-in-out;
  position: relative;
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
  border-width: 2px;
  border-radius: 0.375rem
    /* 6px */
  ;
  --tw-bg-opacity: 1;
  background-color: rgb(33 37 41 / var(--tw-bg-opacity));


}

.carousel-item._scale-75 {
  transform: scale(0.75);
}

.carousel-item._scale-50 {
  transform: scale(0.50);
}

.carousel-item.visible {
  display: block;
}

.carousel-image {
  height: 250px;
  object-fit: cover;
}

.carousel-controls {
  padding: 4px;
  font-size: 30px;
  font-weight: bold;
  color: #000000;
  @apply top-1/2 -translate-y-1/2
}

.next {
  position: absolute;
  top: -5em ;
  right: 0;
  cursor: pointer;
  z-index: 2;
  @apply hover:scale-110 border-[1px] border-transparent hover:bg-main !bg-opacity-10 px-2 rounded-full transition ease-in-out delay-150 duration-300
  active:scale-90 active:shadow-lg active:border-2

}

.prev {
  position: absolute;
  top: -5em ;
  left: 0;
  cursor: pointer;
  z-index: 2;
  @apply hover:scale-110 border-[1px] border-transparent hover:bg-main !bg-opacity-10 px-2 rounded-full transition ease-in-out delay-150 duration-300
  active:scale-90 active:shadow-lg active:border-2 

}

.page-controls {
  position: absolute;
  display: flex;
  justify-content: center;
  gap: 22px;
  width: 100%;
}

.page-controls .page {
  font-size: 3rem;
  cursor: pointer;
  @apply cursor-pointer hover:text-main active:text-main
}

.gradient-div-l {
  @apply absolute inset-0 bg-gradient-to-l from-transparent to-[#f2f2f299]
}

.gradient-div-r {
  @apply absolute inset-0 bg-gradient-to-r from-transparent to-[#f2f2f299]
}

.page-controls .active {
  @apply text-main
}