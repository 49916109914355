.news-image-css {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    background: gray;
}
.blur-mask {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(to top, transparent, black 2px);
    mask-image: linear-gradient(to right, transparent, black 2px),
                linear-gradient(to bottom, transparent, black 2px),
                linear-gradient(to left, transparent, black 2px);
  }