.cover {
    @apply w-full h-[55vh] relative text-center
}

.cover .image {
    @apply w-full object-cover fixed top-0 left-0 h-screen -z-10
}

.cover .title {
    @apply absolute left-1/2 bottom-1/2 -translate-x-1/2 translate-y-1/2 lg:w-[60%] w-full py-4 text-2xl leading-loose text-[#f2f2f2] font-[700] tracking-wide px-8 bg-[#0000004d] capitalize
}

.grid-container-2 {
    @apply grid lg:grid-cols-2 h-full pt-8 pb-10
}

.grid-container-5 {
    @apply grid  grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 h-full pt-8 pb-10 gap-4 
}

.facts-1 {
    @apply w-full bg-main text-[#f2f2f2] tracking-wider py-4
}

.facts-1 .title {
    @apply my-auto lg:text-[2rem] font-bold text-center lg:text-end w-full lg:pr-8
}

.facts-1 .items {
    @apply grid grid-cols-2 w-full
}

.facts-1 .items .top-left {
    @apply border-r border-b py-10 px-5
}

.facts-1 .items .top-right {
    @apply border-b py-10 px-5
}

.facts-1 .items .bottom-left {
    @apply border-r py-10 px-5
}

.facts-1 .items .bottom-right {
    @apply py-10 px-5
}

.facts-1 .items .title {
    @apply text-center text-sm lg:text-[1rem] font-semibold !p-0
}

.facts-1 .items .text {
    @apply text-center text-xs lg:text-sm font-semibold
}

.facts-1 .items .icon {
    @apply text-white h-12 lg:h-20 mx-auto
}

.sections-grid-layout {
    @apply lg:grid grid-cols-2 gap-8 items-start h-full w-full
}

.sections-content {
    @apply w-full px-8 text-justify
}

.sections-grid-layout .title{
    @apply font-semibold py-2 mb-2 text-xl mx-auto lg:mx-0
    col-span-full border-b-main border-b-2 w-fit capitalize
}
.sections-content .title {
    @apply font-semibold py-2 text-xl mx-auto lg:mx-0
     col-span-full border-b-main border-b-2 w-fit capitalize
}

.sections-content .image {
    @apply h-full object-cover mx-auto
}

.sections-grid-layout .image {
    @apply h-full object-cover mx-auto w-full 
}