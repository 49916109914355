.section-container::-webkit-scrollbar {
  display: none;
}

.section-container {
  scroll-behavior: smooth;
}

.hide-small-visible-block {
  @apply hidden lg:block
}
.hide-small-visible-flex {
  @apply hidden lg:flex
}

.visible-small-hide-block {
  @apply block lg:hidden
}
.animate-left {
  animation: slideInLeft 1s ease-in-out;
}
.animate-right {
  animation: slideInRight 1s ease-in-out;
}
@keyframes slideInLeft {
  0% {
    opacity: 0;
    transform: translateX(-10%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideInRight {
  0% {
    opacity: 0;
    transform: translateX(10%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
