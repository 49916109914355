.moveout{
	animation: outime 0.6s;
	transition: all 300ms ease-in-out;
	animation-fill-mode: forwards;
}
.moveon{
	animation: intime 0.6s;
	transition: all 300ms ease-in-out;
	animation-fill-mode: forwards;
}
@keyframes outime{
	0%{
		transform: translateY(-6px);
	}
	100%{
		transform: translateY(-70px);
	}
}
@keyframes intime{
	0%{
		transform: translateY(-70px);
	}
	100%{
		transform: translateY(0);
	}
}
