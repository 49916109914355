.shape-box {
    display: inline-block;
    position: relative;
    z-index: 1;
    max-width: 350px;
    height: 330px;
    margin: 30px 10px 30px;
    box-shadow: 0 6px 30px 0 rgba(0, 0, 0, .12);
    overflow: hidden;
    width: 30rem;
}

.shape-box_half {
    overflow: hidden;
    text-align: left;
}

.shape-box_half:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: skewY(53.5deg);
    transform-origin: top left;
    transition: \transform .4s;
    background: #fff;
    z-index: 1;
 
}

.shape-box>img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}

.bg-black {
    background-color: #000;
}

.shape-box_half figcaption {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0 30px 30px;
    transition: \transform .4s;
    transform: translateY(100%);
    z-index: 3;
}

.shape-box_half figcaption .show-cont {
    position: absolute;
    bottom: calc(100% + 30px);
    left: 30px;
    right: 30px;
    transition: bottom .4s;
}

.card-no {
    font-size: 12px;
    font-weight: bolder;
    color: rgb(217 249 157);
    padding: 0;
    margin: 10px 0;
}

.card-main-title {
    margin-top: 8px;
    font-weight: 700;
    font-size: 16px;
    text-transform: uppercase;
    color: #292b2c;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 60%;
}

.card-content {
    color: #9f9f9f;
    margin-top: 20px;
    line-height: 22px;
    font-size: 12px;
}

.read-more-btn {
    border: 2px solid #db3236;
    font-size: 14px;
    cursor: pointer;
    padding: 10px 20px;
    display: inline-block;
    text-transform: uppercase;
    letter-spacing: .08em;
    font-weight: 600;
    position: relative;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
    background: #db3236;
    color: #fff;
    border-radius: 2px;
    margin-top: 25px;
    text-decoration: none;
}

.read-more-btn:hover {
    background: transparent;
    color: #db3236;

}

.shape-box_half>.after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgb(217 249 157);
    opacity: 0;
    transition: opacity .4s;
}

/*On hover*/
.shape-box_half:hover:before {
    transform: skewY(20deg);
}

.shape-box_half:hover figcaption {
    transform: translateY(0);
}

.shape-box_half:hover figcaption .show-cont {
    bottom: 100%;
}

.shape-box_half:hover>.after {
    opacity: 1;
}
.shape-box_half:hover .card-main-title {
    white-space: normal;
    max-width: 100%; 
}